.branch-tabs .tabs{
    display: inline-flex;
    align-items: center;

}

.branch-tabs .tabs div{
    margin-right: 40px;
    font-size: 16px;
    color: #696969;
    padding-bottom: 5px;
    border-bottom: 2px solid white;

}

.branch-tabs .tabs .active{
    color:#5175F3;
    padding-bottom: 5px;
    border-bottom: 2px solid #5175F3;
}

.branch-tabs .tabs div:hover{
    color:cornflowerblue;
    padding-bottom: 5px;
    border-bottom: 2px solid cornflowerblue;
    cursor: pointer;
}