.team-members{
    width: 100%;

}

.team-members .header{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}



.team-members .header .btn{
    background-color: white;
    padding: 10px;
    border: 1px solid #c5c2c2;
    color: black;
    height: 40px;
    border-radius: 5px;
}

.team-members .header .btn:hover {
    border-color: rgba(0,0,0,0.1);
    background-color: rgba(0,0,0,0.1);
}

.team-members .header .btn .add-sign{
    font-size: 16px;
    margin-right: 10px;
}

.main-body {
    width: 100%;
}

.main-body .table{
    width: 100%;
    margin-top: 2vh;
    overflow-y: scroll;
    max-height: 55vh;
}

.main-body .table table{
    width: 100%;
   
}

.main-body .table table tr{
    border-bottom: 1px solid grey;
}


.main-body .table table thead td{
    padding: 20px;
    color: black;
    font-size: 16px;
    background-color: #F0F0F5;
}

.main-body .table table tr td{
    padding: 20px;
    font-size: 16px;
    border-bottom: 1px solid #F2F2F0;
    color: #696969;
}

.main-body .table table tr td .dp{
    width: 30px;
    background-color: gray;
    margin-right: 10px;
    border-radius: 100%;
    height: 30px;
}

.role{
    padding: 6px;
    /* background-color: #EBE2FF; */
    font-size: 14px;
    border-radius: 10px;
}

.more-btn{
    border: none;
    padding: 12px;
}

.inner-section-team{
    height: 62vh;
    margin-top: 0;
    overflow-y: scroll;


}

.branch-name {
    display: flex;
    gap: 5px;
    font-size: 12px;
    font-weight: 500;
    margin: 30px 0 20px 0;

}

.branch-name-no-margin {
    margin-top: 0;
    display: flex;
    gap: 5px;
    font-size: 12px;
    font-weight: 500;
    margin: 0 0 20px 0;

}

.branch-name > .child-branch {
    color: cornflowerblue;
}

.MuiMenu-list {
    padding: 0 !important;
}