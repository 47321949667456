/* External CSS file or internal <style> tag */
.custom-scrollbar {
    /* Add any other styles you need for your specific element */
  
    /* Custom scrollbar styles */
    
    ::-webkit-scrollbar {
      width: 2px;
    }
  
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
  
    ::-webkit-scrollbar-thumb {
      background: red;
    }
  
    /* Additional styles for hover and other interactions */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  