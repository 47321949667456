*{
    font-family: Mulish;
}

.list-row:hover {
    background-color: #FBFCFC;
    box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.08);
    cursor: pointer;
}

.list-no-hover:hover {
    background-color: #FBFCFC;
    box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.08);
}

.table-wrapper {
    border: 1px solid #E4E9FD;
    border-radius: 10px;

} 