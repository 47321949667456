.sidebar{
    width: 15vw;
    height: 90vh;
    border-right: 1px solid #E6E6E6;
    overflow-y: scroll;
    overflow-x: hidden;
}

.user-account{
    width: 100%;
    height: 80px;
    
    background-color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;

    /* text-align: start; */

}

.user-img{
    height: 2vw;
    width: 2vw;
    border-radius: 100%;
    background-color: ghostwhite;
}

.user-account .user-info {
    margin-left: 10px;
    width: 50%;
} 

.user-info p:nth-child(2){
    font-size: 12px;
    width: 6vw;
    color: white;
    overflow: hidden;
    text-align: start;
    margin: 0;
    margin-top: 5px;
}

.user-info p:nth-child(1){
    font-size: 16px;
    width: 5vw;
    flex-wrap: nowrap;
    text-overflow: ellipsis;
    color: white;
    margin: 0;
}

.user-tabs{
    margin-top: 30px;
}


.user-tabs-inner .tab{
    width: 100%;
}

.user-tabs-inner .tab:hover{
    color: black;
    cursor: pointer;
}




/* .user-tabs-inner .tab .tab-inner{
    padding: 15px 1.5vw;
    color: grey;
    align-items: center;
    font-size: 14px;
} */


.user-tabs-inner .tab .active{
    color: black;
}


/* .user-tabs-inner .tab .tab-inner{
    display: inline-flex;
    justify-content: center;
    align-items: center;
} */


.user-tabs-inner .icons{
    font-size: 18px;
    margin-right: 15px;
    margin-top: 4px;
}

.user-tabs-inner .tab:hover{
    background-color: #E6E6E6;
    cursor: pointer;
}