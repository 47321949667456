.welcome-screen{
    width: 100%;
    height: 90vh
}

.welcome-screen .body{
    width: 100%;
    height: 90vh;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.welcome-screen .header{
    padding: 20px 0px;
}

.welcome-screen .body .inner{
    width: 30vw;
}

.welcome-screen .button button{
    background-color: white;
    width: 100%;
    border: 1px solid #E6E6E6;
}

.welcome-screen .form{
    width: 100%;
    text-align: start;
}

.welcome-screen .form label{
   font-size: 12px;
   font-weight: 400;
   padding-bottom: 15px;
}



.welcome-screen .form input[type="password"]{
    width: 100%;
    border: 1px solid #E6E6E6;
    text-indent: 10px;
    height: 40px;
}

.welcome-screen .form div{
    margin-top: 10px;
    width: 100%;
}

.welcome-screen .submit-btn button{
    padding: 20px;
    background-color: black;
    color: white;
    margin: 20px 0px;
}

.double-row{
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    font-size: 12px;
}

.forgot-pass{
    text-align: end;
}
.forgot-pass span{
    border-bottom: 1px solid black;
}

.enter-pass-label{
    margin: 40px 0px 10px;
    font-size: 12px;
}

.reg-link{
    font-size: 12px;
    color: gray;
    text-align: start;
    width: 100%;
}

.reg-link span span{
    color: black;
    font-weight: w400;
    cursor: pointer;
}

.login-btn{
    display: inline-flex;
    justify-content: center;
    align-items: center;
}


.rounded-input .MuiInputBase-root {
    border-radius: 100px;
}