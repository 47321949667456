
.SignUpScreen .sign-body{
    width: 100%;
    height: 90vh;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.SignUpScreen .sign-body .sign-inner{
    width: 60%;
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.SignUpScreen .sign-body .sign-inner section{
    width: 55%;
}

.sign-inner .form {
    width: 80%;
    text-align: start;
}

/* .sign-inner .form label{
    font-size: 12px;
} */

/* .sign-inner .form input[type="text"]{
    width: 98%;
    margin: 10px 0px;
    border: 1px solid #E6E6E6;
    padding: 14px 10px;
} */

/* .sign-inner .form input[type="number"]{
    width: 98%;
    margin: 10px 0px;
    border: 1px solid #E6E6E6;
    padding: 14px 10px;
}

.sign-inner .form input[type="password"]{
    width: 98%;
    margin: 10px 0px;
    border: 1px solid #E6E6E6;
    padding: 14px 10px;
} */

.select-input{
    width: 100%;
    padding: 10px 0px;
    outline: none;
    border: none;
    background-color: white;
}

hr{
    background-color: #E6E6E6 !important;
}

.sign-inner .form .submit-btn button{
    padding: 20px;
    background-color: black;
    border: none;
    width: 105%;
    color: white;
    margin: 20px 0px;
}

.info{
    margin: 30px 0px;
    color: grey;
    font-size: 16px;
}

.sign-inner .main{
    margin-top: 20px;
}

.your-skill-cont{
    margin-top: 30px;
}


.your-skill{
    height: 70px;
}

.chosen-skills{
    height: 90px;
    font-size: 12px;
    border: 1px solid #E6E6E6;
    width: 99%;
    margin: 5px 0px;
    display: inline-flex;
    flex-wrap: wrap;
    overflow: scroll;
    padding: 10px;
}

.chosen-skills .item{
    padding: 2px;
    margin: 6px;
    border-radius: 2px;
    background-color: #E6E6E6;
}

.chosen-skills .cont{
    margin: 7px 0px;
}

.phone{
    margin-bottom: 90px;
}


.upload-pic{
    padding: 25px 10px;
    display: inline-flex;
    font-size: 12px;
    visibility: hidden;
    margin: 50px 0px;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    border: 1px dashed #E6E6E6;
}

.select-input-cont{
    /* display: inline-flex; */
    /* justify-content: center; */
    width: 100%;
    color: gray;
    margin-bottom: 10px;
    padding: 3px 8px;
    margin-top: 10px;
    /* align-items: center; */
    border: 1px solid #E6E6E6;
}

option .select-country-label{
    color: #A5A5A5;
    font-size: 12px;
}

.rounded-input .MuiInputBase-root {
    border-radius: 100px;
}