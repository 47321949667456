.shift-nav{
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}

.shift-nav .left {
    display: flex;
    gap: 20px;
}

.shift-nav .dropdown span{
    padding: 10px;
    margin-right: 20px;
    border: 1px solid #E6E6E6;
    border-radius: 2px;
}

.shift-nav .dropdown select{
    border: none;
    outline: none;
}

.shift-nav .dropdown select option{
    font-size: 12px;
}


.shift-nav  .button{
    border: 1px solid black;
    font-size: 13px;
    padding: 10px;
}

.shift-table{
    width: 100%;
}


.shift-table table{
    width: 100%;
}

.shift-table td{
    /* padding: 15px;
    height: 50px; */
    border-bottom: 1px solid #E6E6E6;
    border-right: 1px solid #E6E6E6;
}

.td-padding{
    padding: 15px;
}


table{
    width: 100%;
}

.cursor {
    cursor: pointer;
}
