.before-table {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    justify-content: space-between;
}


.list-table{
    width: 100%;
}

.white-bg{
    background-color: white;
}

.list-table td{
    /* padding: 15px;
    height: 50px; */
    border-bottom: 1px solid #E6E6E6;
    border-right: 1px solid #E6E6E6;
}

.td-padding{
    padding: 15px;
}


table{
    width: 100%;
}



    
    