.before-table {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.before-table .filter {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.first-td {
    border: 1px solid #F1F4FE;
}

.bmo {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    font-size: 14px;
    margin-left: 4rem;
    margin-top: 2rem;

}

.bmo div{
    width: 15px;
    height: 15px;
    background-color: #7491F5;
    }
    
    
    