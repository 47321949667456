.createCompanyForm {
    display: inline-flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    /* align-items: center; */
}

.createCompanyForm .btns {
    width: 80%;
    display: inline-flex;
    margin-top: 1vh;
    justify-content: space-between;

}

.createCompanyForm form{
    width:100%;
    display: inline-flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

.createCompanyForm .inner {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

/* .createCompanyForm .inner .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    place-items: center;
} */

.createCompanyForm .inner .form .label {
    margin: 7px 0px;
    text-align: start;
    font-size: 12px;
    font-weight: 500;
    width: 85%;
}

.form-item {
    width: 100%;
    margin-bottom: 5px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: start;
}



.createCompanyForm .inner .form input {
    padding: 13px 10px;
    width: 80%;
    border: 1px solid #E6E6E6;
}

.createCompanyForm .inner .form select {
    width: 97%;
    padding: 13px 10px;
    outline: none;
    border: 0px;
}

.createCompanyForm .inner .form .select {
    border: 1px solid #E6E6E6;
    width: 84%;
}



.createCompanyForm .btns button {
    padding: 10px 25px;
}


.createCompanyForm .btns .cancel {
    background-color: white;
    border-radius: 2px;
    color: black;
    border: 1px solid black;
}

.createCompanyForm .btns .submit {
    background-color: black;
    border-radius: 2px;
    color: white;
    border: 1px solid black;
}

.tracker {
    height: 50px;
}