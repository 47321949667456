.no-company{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 85vh;
    flex-direction: column-reverse;
}
.no-company .inner_{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    margin-top: 2vh;
    height: 85vh;
    box-shadow: 10px 15px 45px rgba(0, 0, 0, 0.05);
    flex-direction: column;
}

.no-company p{
    font-size: 12px;
}

