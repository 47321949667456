.slot-card{
    padding: 10px;
    background-color: "#EDFBFF";
}
.slot-card p{
    font-size: 10px;
}

.time-stamp{
    font-size: 13px;
    padding-top: 10px;
}