.verify-email{
    width: 100%;
    height: 85vh;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.inner-verify-email{
    width: 60%;
    height: 60vh;
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-shadow: 10px 15px 45px rgba(0, 0, 0, 0.05);
}