.branch-table{
    width: 100%;
    max-height: 52vh;
    overflow-y: scroll;
}

.branch-table table{
    width: 100%;
    margin-top: 2vh;
}

.branch-table table thead{
    font-weight: 500;
    background-color: #F0F0F5;
}

.branch-table table thead th{
    padding: 20px;
    font-size: 16px;
    text-align: left;
}


.branches_ .header{
    width: 100%;
    height: 9vh;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}



.branches_ .header .btn{
    background-color: white;
    padding: 10px;
    border: 1px solid #c5c2c2;
    color: black;
    height: 40px;
    border-radius: 5px;
}

.branches_ .header .btn:hover {
    border-color: rgba(0,0,0,0.1);
    background-color: rgba(0,0,0,0.1);
}

.branches_ .header .btn .add-sign{
    font-size: 16px;
    margin-right: 10px;
}

.branch-table table tr td{
    padding: 20px;
    font-size: 16px;
    border-bottom: 1px solid #F2F2F0;
    color: #696969;
}

.branch-table table tr:hover{
    background-color:#f8f8f4;
}

.inner-section{
    margin-top: 4vh;
    width: 100%;
    padding: 0px 0px;
    box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.08);
}

/*  */

.search-box{
    margin-left: 20px;
    display: flex;
    gap: 10px;
    width: 245px;
}
