.successView .inner{
    box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 30px 0px;
}

.successView .inner img{
    width: 15%;
    margin-bottom: 20px;
}

.back-check{
    padding: 10px;
    width: 40px;
    margin: 10px 0px;
    height:  40px;
    background-color: #73DE8A;
    display: inline-flex;
    justify-content: center;
    font-size: 35px;
    color: white;
    border-radius: 100%;
    align-items: center;
}

.successView .inner button{
    width: 70%;
    margin: 20px 0px;
    border: none;
    color: white;
    padding: 15px;
    background-color: black;

}