.search-box-jobs{
    margin-top: 2vh;
    padding: 10px 10px;
    border-radius: 5px;
    border: 1px solid #c5c2c2;
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
    width: 245px;
}

.search-box-jobs input{
    border: none;
    background-color: transparent;
    outline: none;
    width: 20vw;
}

.container-wrapper {
    height: 70vh;
    border: 1px solid #E4E9FD;
    border-radius: 10px;
    font-family: mulish;
    overflow-y: scroll;
    padding: 20px 30px;
}

.container-wrapper .top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn{
    font-family: mulish;
    background-color: white;
    padding: 10px;
    border: 1px solid #c5c2c2;
    color: black;
    height: 40px;
    border-radius: 5px;
    margin-right: 20px; 
    cursor: pointer;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px
}

.btn .text {margin: 0; margin-bottom: 5px;}

btn:hover {
    border-color: rgba(0,0,0,0.1);
    background-color: rgba(0,0,0,0.1);
}


.list-row:hover {
    background-color: #FBFCFC;
    box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.08);
    cursor: pointer;
}

.list-no-hover:hover {
    background-color: #FBFCFC;
    box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.08);
}

