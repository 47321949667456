@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Mulish";
  src: local("Mulish"),
    url("./fonts/Mulish-Regular.ttf") format("truetype");
  /* font-weight: bold; */
}

@font-face {
  font-family: "PlusJakarta";
  src: local("PlusJakarta"),
    url("./fonts/PlusJakartaSans-Regular.ttf") format("truetype");
  /* font-weight: bold; */
}
body * {
  margin: 0;
  font-family: "Mulish", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-family: "Mulish", sans-serif !important;
}

code {
  font-family: "Mulish", sans-serif !important;
}

.MuiDrawer-paperAnchorRight {
  box-shadow: -45px 15px 45px 0px rgba(0, 0, 0, 0.05) !important;
}

.MuiPopover-paper {
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.08) !important;
}

/* @tailwind base;
@tailwind components;
@tailwind utilities; */

.bottom-arrow {
  display: flex;
  justify-content: center;
  position: relative;
}

.bottom-arrow::after {
  content: "  ";
  height: 0;
  width: 0;
  border-top: 20px solid white;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  position: absolute;
  top: 100%;
}


.left-arrow{
display: flex;
position: relative;
align-items: center;
}


.left-arrow::after {
  content: " ";
  width: 0;
  height: 0;
  border-left: 20px solid white;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  position:absolute;
  left: 100%;
}


.top-arrow{
  display: flex;
  justify-content: center;
  position: relative;
}


.top-arrow::after {
  content: " ";
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid white;
  position:absolute;
  bottom: 100%;
}

.MuiPopper-root {
  z-index: 1000;
}