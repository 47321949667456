.task-card{
    padding: 10px 15px;
    background-color: white;
    margin: 10px;
    border-radius: 5px;
}

.task-card ._title{
    color: black;
    font-weight: bold;
    font-size: 17px;
}

.task-card ._body{
    color: gray;
    padding: 10px 0px;
    font-size: 15px;
    margin-bottom: 30px;
}

.task-card .chat{
    font-size: 25px;
    color: #A3A3A3;
}

.assign-section{
    display: grid;
    grid-template-columns: .8fr 6fr 1fr;
    width: 100%;
    padding: 20px 0px 5px;
    column-gap: 10px;
}

.task-priority span{
    color: aqua;
    background-color: aqua;
    border-radius: 5px;
    font-size: 14px;
    padding: 5px;
}

.user-initials span{
    background-color: #5175F3;
    padding: 5px;
    color: white;
    font-size: 14px;
    height: 20px;
    border-radius: 100%;
    width: 20px;
}