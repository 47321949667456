.task-container{
    width: 100%;
    background-color: #EBEBEB;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.task-container .header{
    padding: 10px;
    background-color: grey;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-weight: bold;
    color: white;
}

.task-container .header .badge{
    padding: 1px 5px;
    margin-left: 2px;
    background-color: grey;
}


/* .task-container .list{
   
} */