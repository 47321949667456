.navigate-time{
    display: inline-flex;
    align-items: center;
}
.navigate-time button{
    padding: 0px 0.7rem;
    height: 38px;
    background-color: white;
    font-size: 14px;
    border: 1px solid #E6E6E6;
}

.navigate-time .range{
    padding: 0px 0.7rem;
    border: none;
    border-top: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
    height: 38px;
    font-size: 13px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    border-left:none;
    border-right: none;
}