.add-button{
    width: 90%;
    padding: 5px;
    background-color: white;
    border-radius: 5px;
    display: inline-flex;
    color: #D7D7D7;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 40px 0px 20px 0px;
    font-size: 24px;
}