.upload-area{
    height: 70px;
    border: 1px solid #E6E6E6;
    width: 84%;
    border-style: dashed;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
    margin: 12px 0px 0px 0px;
}
