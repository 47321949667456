.verify-email-body{
    width: 100%;
    height: 90vh;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.the-card{
    width: 40%;
    padding: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 10px 15px 45px rgba(0, 0, 0, 0.05);

}