.create-account {
    display: inline-flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    /* align-items: center; */
}

.create-account .btns {
    width: 92%;
    display: inline-flex;
    margin-top: 3vh;
    justify-content: space-between;

}

.create-account .inner {
    width: 90%;
    display: inline-flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

.create-account form{
    width:100%;
    display: inline-flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

/* .create-account .inner .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    place-items: center;
} */

.create-account .inner .form .label {
    margin: 7px 0px;
    text-align: start;
    font-size: 12px;
    font-weight: 500;
    width: 85%;
}

.form-item {
    width: 100%;
    margin-bottom: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px 0px;
    text-align: start;
}



.create-account .inner .form input {
    padding: 12px;
    width: 80%;
    border: 1px solid #E6E6E6;
}

.create-account .inner .form select {
    width: 94%;
    padding: 12px;
    outline: none;
    border: 0px;
}

.create-account .inner .for

.create-account .inner .form select option{
    color: rgb(161, 157, 157);
    background-color: white;
}

.create-account .inner .form .select {
    border: 1px solid #E6E6E6;
    width: 85%;
}



.create-account .btns button {
    padding: 10px 25px;
}


.create-account .btns .cancel {
    background-color: white;
    border-radius: 2px;
    border: 1px solid black;
    cursor: pointer;
}

.create-account .btns .submit {
    background-color: black;
    border-radius: 2px;
    color: white;
    border: 1px solid black;
    cursor: pointer;
}

.create-account .btns .submit:hover {
    background-color: rgba(0,0,0,0.5);
    border-color: rgba(0,0,0,0.1);
    /* border: none; */
}

.create-account .btns .cancel:hover {
    border-color: rgba(0,0,0,0.1);
    background-color: rgba(0,0,0,0.1);
    color: rgba(0,0,0,0.5);
}

.tracker {
    height: 50px;
}