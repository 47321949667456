.customers-settings{
    width: 100% !important;
    margin-top: 4vh !important;
    display: inline-flex !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
}

.customers-settings form{
    display: grid !important;
    width: 90% !important;
    grid-template-columns: 1fr 1fr !important; 
    justify-items: start !important;
    align-content: flex-start !important;
}

.customers-settings form input{
    width: 90%;
    padding: 15px;
    background-color: #F7F7F7;
    border: none;
}

.customers-settings .formitem{
    width: 90%;
    text-align: start;
    font-size: 16px;

    /* margin: 10px 0px; */
}

.customers-settings .form-item div:nth-child(2){
    width: 100%;
}

.customers-settings .formitem .label{
    width: 100%;
    margin: 25px 0px 10px 0px;
    text-align: start;
}