.user-profile{
    display: inline-flex;
    justify-content: center;
    margin-top: 30px;
    align-items: center;
}
.user-profile .dp{
    width: 7vw;
    height: 7vw;
    background-color: black;
    border-radius: 100%;
    margin-right: 30px;
}

.user-profile .the-details{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    color: #5A5A5A;
    font-size: 14px;
}

.Licenses {
    margin-top: 40px;
}

.Licenses table {
    /* border: 1px solid black;
    padding: 10px; */
    /* padding-bottom: 10px; */
    /* border-radius: 10px; */
}


.Licenses .header{
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.Licenses .header button{
    height: 40px;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 14px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}

._license{
    display: inline-flex;
    align-items: center;
    justify-content: center;
}


.Licenses table thead td{
    padding: 20px 10px 20px 10px;
    color: black;
    font-size: 14px;
    background-color: #F0F0F5;
}

.Licenses table tr td{
    padding: 20px 0px 20px 30px;
    font-size: 14px;
    /* border-bottom: 1px solid #F2F2F0; */
}
