.details-table{
    border: 1px solid #E4E9FD;
    width: 100%;
    margin: 7vh 0px 7vh 0px;
    height: 50vh;
}

.details-table .header{
    padding: 10px;
    border-bottom: 1px solid #E4E9FD;
}

.details-table .body{
    padding: 10px;
    width: unset;
}

.details-table .body .col{
    padding: 10px 0px;
    display: inline-flex;
    font-size: 14px;
    align-items: flex-start;
}

.details-table .body .col div:nth-child(2){
    margin-left: 20px;
}

/* .details-table .body .col > span{

} */

.comment-area{
    width: 100%;
}
.comment-area input{
    width: 100%;
    padding: 10px 0px;
    text-indent: 5px;
    border: 1px solid #E4E9FD;
}

.comment-area .btns{
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
}

.comment-area .btns button{
    padding: 8px 15px;
    margin-left: 10px;
    border-radius: 3px;
}

.comment-area .btns .save{
    border: none;
    background-color: black;
    color: white;
}

.comments{
    display: inline-flex;
    align-items: center;
    margin: 15px 0px;
    width: 100%;
}

.comment-list{
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    width: 70%;
}

.comments .img{
    padding:10px;
    width:14px;
    background-color: black;
    color: white;
    font-size: 12px;
    height:14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-right: 10px;
}
.comments ._comment{
    font-size: 14px;
    width: 100%;
}

.priority-badge{
    padding: 7px;
    /* background-color: #F5E4E4;
    color: #9A4242; */
    border-radius:10px;
}