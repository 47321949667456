.Navbar{
    width: 100%;
    height: 8vh;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.Navbar .inner{
    display: inline;
    align-items: center;
    justify-content: center;
    width: 95%;
}

.logo{
    height: 4vh;
}