.search-box{
    display: inline-flex;
    justify-content: space-between;
    margin-top: 2vh;
    padding: 10px 10px;
    border-radius: 5px;
    border: 1px solid #c5c2c2;
    margin-bottom: 10px;
}

.search-box input{
    border: none;
    background-color: transparent;
    outline: none;
    width: 20vw;
}

.branch-table{
    width: 100%;
    height: 67vh;
}

.btn{
    background-color: white;
    padding: 10px;
    border: 1px solid #c5c2c2;
    color: black;
    height: 40px;
    border-radius: 5px;
}
.btn:hover {
    border-color: rgba(0,0,0,0.1);
    background-color: rgba(0,0,0,0.1);
}