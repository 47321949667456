.homepage-navbar{
    width: 100%;
    height: 9vh;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #E6E6E6;
}

.homepage-navbar .nav-inner{
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.homepage-navbar .nav-inner .logo{
    height: 3.5vh;
    
}

.logo-cont{
    width: 15.3%;
    height: 9vh;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #F2F5FF;
}

.homepage-navbar .nav-inner .side{
    width: 86%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.homepage-navbar .side-inner{
    width: 95%;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}

.trailing{
    display: inline-flex;
    align-items: center;
    margin-right: 50px;
}

.trailing .label{
    font-size: 14px;
}

.trailing .label .email{
    font-size: 12px;
    color: grey;
}

.trailing .divider{
    width: 1px;
    background-color: grey;
    height: 4vh;
    margin: 0px 20px;
}

.caret{
    font-size: 20px;
    margin-right:20px;
    margin-left: 10px;
}

.trailing .img-cont{
    width: 5vh;
    height: 5vh;
    margin: 0px 20px;
    border-radius: 100%;
    background-color: grey;
}

.trailing .notification{
    font-size: 25px;
    margin: 0px 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}