
.App{
  width: 100%;
  height: 100vh;
  color: black;
  font-family: "PT Sans";
}

.black-btn{
  border: none;
  color: white;
  background-color:black;
  padding: 10px 20px;
}


.error-msg{
  font-size: 12px;
  color: red;
  margin: 20px 0px;
  width: 100%;
}

.menu-text{
  font-size: 12px;
}

select{
  -webkit-appearance: none;
}