.homepage{
    width: 100%;
    height: 100vh
}

.homepage .body{
    width: 100%;
    height: 90vh;
}

.home-page-inner{
    width: 100%;
    height: 90vh;
    display: inline-flex;
    justify-content: space-between;
}

.home-page-inner .overview{
    width: 98%;
    height: 85vh;
    margin-top: 100px;
}

.create_btn{
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
}

.create_btn .button{
    padding: 5px 10px;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 12px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}

.create_btn .button span{
    font-size: 15px;
    margin-right: 10px;
}

.home-page-inner .overview .body{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.three-columns{
    margin-top: 20px;
    display: grid;
    font-size: 14px;
    grid-template-columns: 1fr 1fr 1fr;
}

.three-columns .val{
    font-size: 23px;
}

.label2{
    margin-top: 5vh;
}

.shifts{
    width: 100%;
    height: 43vh;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: grey;
}

p {
    margin: 0;
    padding: 0;
}

.two-columns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* margin-left: 20px; */
    margin-top: 20px;
}

.two-columns > div {
    border: 1px solid #E4E9FD;
    display: flex;
    flex-direction: column;
    width: 480px;
    padding: 20px;
    gap: 10px;
    border-radius: 5px;
}

.two-columns > div .first-row {
    width: 119px;
    align-self: flex-end;
}

.two-columns > div .second-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
}

.two-columns > div .second-row > div {
    display: flex;
    flex-direction: column;
    border: 1px solid #E4E9FD;
    width: 230px;
    height: 70px;
    padding: 10px 20px;
    border-radius: 5px;
}

.two-columns > div .second-row div > .text1 {
    font-size: 14px;
    color: #696969;
}

.two-columns > div .second-row div > img {
    height: 30px;
    width: 30px;
}

.two-columns > div .second-row div > .text2 {
   font-size: 24px;
}

.two-columns .right-col .first-row {
    margin: 10px 0;
    align-self: flex-start;
 }

.margin-forward {
    margin-left: 30px;
}

.section-header {
    color: #444444;
    font-size: 16px;
    font-weight: 500;
    padding: 15px 15px 5px 15px;
}

.dashboard-section-dropdown {
    padding-top: 15px;
}

.dashboard-announcement-table {
    width: 95%;
    margin-left: 15px;
    padding-top: 25px;
}

.dashboard-section-tablelist {
    padding-left: 15px;
    width: 95%;
}

.dashboard-section-searchbar {
    margin-left: 15px;
}